// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-installing-spark-angular-mdx": () => import("./../src/pages/installing-spark/angular.mdx" /* webpackChunkName: "component---src-pages-installing-spark-angular-mdx" */),
  "component---src-pages-installing-spark-html-add-components-mdx": () => import("./../src/pages/installing-spark/html-add-components.mdx" /* webpackChunkName: "component---src-pages-installing-spark-html-add-components-mdx" */),
  "component---src-pages-installing-spark-html-environment-setup-mdx": () => import("./../src/pages/installing-spark/html-environment-setup.mdx" /* webpackChunkName: "component---src-pages-installing-spark-html-environment-setup-mdx" */),
  "component---src-pages-installing-spark-html-installation-mdx": () => import("./../src/pages/installing-spark/html-installation.mdx" /* webpackChunkName: "component---src-pages-installing-spark-html-installation-mdx" */),
  "component---src-pages-installing-spark-installing-fonts-mdx": () => import("./../src/pages/installing-spark/installing-fonts.mdx" /* webpackChunkName: "component---src-pages-installing-spark-installing-fonts-mdx" */),
  "component---src-pages-installing-spark-installing-icons-mdx": () => import("./../src/pages/installing-spark/installing-icons.mdx" /* webpackChunkName: "component---src-pages-installing-spark-installing-icons-mdx" */),
  "component---src-pages-installing-spark-mdx": () => import("./../src/pages/installing-spark.mdx" /* webpackChunkName: "component---src-pages-installing-spark-mdx" */),
  "component---src-pages-installing-spark-react-mdx": () => import("./../src/pages/installing-spark/react.mdx" /* webpackChunkName: "component---src-pages-installing-spark-react-mdx" */),
  "component---src-pages-principles-accessibility-guidelines-mdx": () => import("./../src/pages/principles/accessibility-guidelines.mdx" /* webpackChunkName: "component---src-pages-principles-accessibility-guidelines-mdx" */),
  "component---src-pages-principles-class-naming-convention-mdx": () => import("./../src/pages/principles/class-naming-convention.mdx" /* webpackChunkName: "component---src-pages-principles-class-naming-convention-mdx" */),
  "component---src-pages-using-spark-components-accordion-mdx": () => import("./../src/pages/using-spark/components/accordion.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-accordion-mdx" */),
  "component---src-pages-using-spark-components-alert-mdx": () => import("./../src/pages/using-spark/components/alert.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-alert-mdx" */),
  "component---src-pages-using-spark-components-autocomplete-mdx": () => import("./../src/pages/using-spark/components/autocomplete.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-autocomplete-mdx" */),
  "component---src-pages-using-spark-components-award-mdx": () => import("./../src/pages/using-spark/components/award.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-award-mdx" */),
  "component---src-pages-using-spark-components-box-mdx": () => import("./../src/pages/using-spark/components/box.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-box-mdx" */),
  "component---src-pages-using-spark-components-button-mdx": () => import("./../src/pages/using-spark/components/button.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-button-mdx" */),
  "component---src-pages-using-spark-components-card-mdx": () => import("./../src/pages/using-spark/components/card.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-card-mdx" */),
  "component---src-pages-using-spark-components-centered-column-mdx": () => import("./../src/pages/using-spark/components/centered-column.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-centered-column-mdx" */),
  "component---src-pages-using-spark-components-dictionary-mdx": () => import("./../src/pages/using-spark/components/dictionary.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-dictionary-mdx" */),
  "component---src-pages-using-spark-components-divider-mdx": () => import("./../src/pages/using-spark/components/divider.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-divider-mdx" */),
  "component---src-pages-using-spark-components-dropdown-mdx": () => import("./../src/pages/using-spark/components/dropdown.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-dropdown-mdx" */),
  "component---src-pages-using-spark-components-flag-mdx": () => import("./../src/pages/using-spark/components/flag.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-flag-mdx" */),
  "component---src-pages-using-spark-components-footer-mdx": () => import("./../src/pages/using-spark/components/footer.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-footer-mdx" */),
  "component---src-pages-using-spark-components-highlight-board-mdx": () => import("./../src/pages/using-spark/components/highlight-board.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-highlight-board-mdx" */),
  "component---src-pages-using-spark-components-icon-mdx": () => import("./../src/pages/using-spark/components/icon.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-icon-mdx" */),
  "component---src-pages-using-spark-components-input-mdx": () => import("./../src/pages/using-spark/components/input.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-input-mdx" */),
  "component---src-pages-using-spark-components-link-mdx": () => import("./../src/pages/using-spark/components/link.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-link-mdx" */),
  "component---src-pages-using-spark-components-list-mdx": () => import("./../src/pages/using-spark/components/list.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-list-mdx" */),
  "component---src-pages-using-spark-components-masthead-mdx": () => import("./../src/pages/using-spark/components/masthead.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-masthead-mdx" */),
  "component---src-pages-using-spark-components-modal-mdx": () => import("./../src/pages/using-spark/components/modal.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-modal-mdx" */),
  "component---src-pages-using-spark-components-pagination-mdx": () => import("./../src/pages/using-spark/components/pagination.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-pagination-mdx" */),
  "component---src-pages-using-spark-components-promo-mdx": () => import("./../src/pages/using-spark/components/promo.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-promo-mdx" */),
  "component---src-pages-using-spark-components-stack-mdx": () => import("./../src/pages/using-spark/components/stack.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-stack-mdx" */),
  "component---src-pages-using-spark-components-stepper-mdx": () => import("./../src/pages/using-spark/components/stepper.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-stepper-mdx" */),
  "component---src-pages-using-spark-components-table-mdx": () => import("./../src/pages/using-spark/components/table.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-table-mdx" */),
  "component---src-pages-using-spark-components-tabs-mdx": () => import("./../src/pages/using-spark/components/tabs.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-tabs-mdx" */),
  "component---src-pages-using-spark-components-toggle-mdx": () => import("./../src/pages/using-spark/components/toggle.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-toggle-mdx" */),
  "component---src-pages-using-spark-components-tooltip-mdx": () => import("./../src/pages/using-spark/components/tooltip.mdx" /* webpackChunkName: "component---src-pages-using-spark-components-tooltip-mdx" */),
  "component---src-pages-using-spark-foundations-color-accessibility-mdx": () => import("./../src/pages/using-spark/foundations/color-accessibility.mdx" /* webpackChunkName: "component---src-pages-using-spark-foundations-color-accessibility-mdx" */),
  "component---src-pages-using-spark-foundations-color-codes-mdx": () => import("./../src/pages/using-spark/foundations/color-codes.mdx" /* webpackChunkName: "component---src-pages-using-spark-foundations-color-codes-mdx" */),
  "component---src-pages-using-spark-foundations-color-usage-mdx": () => import("./../src/pages/using-spark/foundations/color-usage.mdx" /* webpackChunkName: "component---src-pages-using-spark-foundations-color-usage-mdx" */),
  "component---src-pages-using-spark-foundations-css-utilities-mdx": () => import("./../src/pages/using-spark/foundations/css-utilities.mdx" /* webpackChunkName: "component---src-pages-using-spark-foundations-css-utilities-mdx" */),
  "component---src-pages-using-spark-foundations-design-tokens-mdx": () => import("./../src/pages/using-spark/foundations/design-tokens.mdx" /* webpackChunkName: "component---src-pages-using-spark-foundations-design-tokens-mdx" */),
  "component---src-pages-using-spark-foundations-space-mdx": () => import("./../src/pages/using-spark/foundations/space.mdx" /* webpackChunkName: "component---src-pages-using-spark-foundations-space-mdx" */),
  "component---src-pages-using-spark-foundations-typography-mdx": () => import("./../src/pages/using-spark/foundations/typography.mdx" /* webpackChunkName: "component---src-pages-using-spark-foundations-typography-mdx" */),
  "component---src-pages-using-spark-guides-alt-text-mdx": () => import("./../src/pages/using-spark/guides/alt-text.mdx" /* webpackChunkName: "component---src-pages-using-spark-guides-alt-text-mdx" */),
  "component---src-pages-using-spark-guides-content-style-mdx": () => import("./../src/pages/using-spark/guides/content-style.mdx" /* webpackChunkName: "component---src-pages-using-spark-guides-content-style-mdx" */),
  "component---src-pages-using-spark-guides-mdx": () => import("./../src/pages/using-spark/guides.mdx" /* webpackChunkName: "component---src-pages-using-spark-guides-mdx" */),
  "component---src-pages-using-spark-guides-validation-messaging-mdx": () => import("./../src/pages/using-spark/guides/validation-messaging.mdx" /* webpackChunkName: "component---src-pages-using-spark-guides-validation-messaging-mdx" */),
  "component---src-pages-using-spark-guides-writing-meaningful-web-content-with-semantic-html-mdx": () => import("./../src/pages/using-spark/guides/writing-meaningful-web-content-with-semantic-html.mdx" /* webpackChunkName: "component---src-pages-using-spark-guides-writing-meaningful-web-content-with-semantic-html-mdx" */),
  "component---src-pages-using-spark-mdx": () => import("./../src/pages/using-spark.mdx" /* webpackChunkName: "component---src-pages-using-spark-mdx" */)
}

