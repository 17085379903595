import folderStructure from "../../../../src/images/installing-spark/html/folder-structure.png";
import bundledJs from "../../../../src/images/installing-spark/html/bundled-js.png";
import greenBg from "../../../../src/images/installing-spark/html/green-bg.png";
import helloSpark from "../../../../src/images/installing-spark/html/hello-spark.png";
import * as React from 'react';
export default {
  folderStructure,
  bundledJs,
  greenBg,
  helloSpark,
  React
};