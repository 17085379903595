import reactApp from "../../../../src/images/installing-spark/react/react-app.png";
import styleCheck from "../../../../src/images/installing-spark/react/style-check.png";
import buttonLoaded from "../../../../src/images/installing-spark/react/button-loaded.png";
import spinnergif from "../../../../src/images/installing-spark/html/html-spinner.gif";
import * as React from 'react';
export default {
  reactApp,
  styleCheck,
  buttonLoaded,
  spinnergif,
  React
};