module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/spark-logo.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3bc85c67cf88dc03ddfd9716c2744813"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"homepage-pages":"/opt/build/repo/src/components/layouts/HomepageLayout.js","installing-spark-pages":"/opt/build/repo/src/components/layouts/InstallingSparkLayout.js","installing-spark-page":"/opt/build/repo/src/components/layouts/InstallingSparkLayout.js","guides":"/opt/build/repo/src/components/layouts/UsingSparkLayout.js","using-spark-pages":"/opt/build/repo/src/components/layouts/UsingSparkLayout.js","using-spark-page":"/opt/build/repo/src/components/layouts/UsingSparkLayout.js","principles-pages":"/opt/build/repo/src/components/layouts/PrinciplesLayout.js","default":"/opt/build/repo/src/components/layouts/Layout.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-113915182-1","head":false,"respectDNT":true,"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
