import ColorSwatchGrid from "../../../../src/components/ColorSwatchGrid";
import ColorSwatch from "../../../../src/components/ColorSwatch";
import doRed from "../../../../src/images/do-red.png";
import doRed2x from "../../../../src/images/do-red@2x.png";
import dontRed from "../../../../src/images/dont-red.png";
import dontRed2x from "../../../../src/images/dont-red@2x.png";
import doPurple from "../../../../src/images/do-purple.png";
import doPurple2x from "../../../../src/images/do-purple@2x.png";
import dontPurple from "../../../../src/images/dont-purple.png";
import dontPurple2x from "../../../../src/images/dont-purple@2x.png";
import doGray from "../../../../src/images/do-gray.png";
import doGray2x from "../../../../src/images/do-gray@2x.png";
import dontGray from "../../../../src/images/dont-gray.png";
import dontGray2x from "../../../../src/images/dont-gray@2x.png";
import doSemantic from "../../../../src/images/do-semantic.png";
import doSemantic2x from "../../../../src/images/do-semantic@2x.png";
import dontSemantic from "../../../../src/images/dont-semantic.png";
import dontSemantic2x from "../../../../src/images/dont-semantic@2x.png";
import stepperExample from "../../../../src/images/stepper-example.png";
import stepperExample2x from "../../../../src/images/stepper-example@2x.png";
import stepperExample3x from "../../../../src/images/stepper-example@3x.png";
import chart1 from "../../../../src/images/chart-1.png";
import chart12x from "../../../../src/images/chart-1@2x.png";
import chart2 from "../../../../src/images/chart-2.png";
import chart22x from "../../../../src/images/chart-2@2x.png";
import howToApplyColorProportionally from "../../../../src/images/how-to-apply-color-proportionally.png";
import howToApplyColorProportionally2x from "../../../../src/images/how-to-apply-color-proportionally@2x.png";
import { SprkStack, SprkStackItem, SprkButton, SprkFlag, SprkHeading, SprkLink, SprkInputContainer, SprkLabel, SprkInput, SprkIcon, SprkDivider, SprkText } from '@sparkdesignsystem/spark-react';
import * as React from 'react';
export default {
  ColorSwatchGrid,
  ColorSwatch,
  doRed,
  doRed2x,
  dontRed,
  dontRed2x,
  doPurple,
  doPurple2x,
  dontPurple,
  dontPurple2x,
  doGray,
  doGray2x,
  dontGray,
  dontGray2x,
  doSemantic,
  doSemantic2x,
  dontSemantic,
  dontSemantic2x,
  stepperExample,
  stepperExample2x,
  stepperExample3x,
  chart1,
  chart12x,
  chart2,
  chart22x,
  howToApplyColorProportionally,
  howToApplyColorProportionally2x,
  SprkStack,
  SprkStackItem,
  SprkButton,
  SprkFlag,
  SprkHeading,
  SprkLink,
  SprkInputContainer,
  SprkLabel,
  SprkInput,
  SprkIcon,
  SprkDivider,
  SprkText,
  React
};