import ComponentPreview from "../../../../src/components/ComponentPreview";
import { SprkStack, SprkStackItem, SprkFlag, SprkHeading, SprkIcon, SprkDivider, SprkText } from '@sparkdesignsystem/spark-react';
import pageTitleDo from "../../../../src/images/typography/pagetitles_do.png";
import pageTitleDo2x from "../../../../src/images/typography/pagetitles_do@2x.png";
import pageTitleDont from "../../../../src/images/typography/pagetitle_dont1.png";
import pageTitleDont2x from "../../../../src/images/typography/pagetitle_dont1@2x.png";
import pageTitleDont2 from "../../../../src/images/typography/pagetitle_dont2.png";
import pageTitleDont2_2x from "../../../../src/images/typography/pagetitle_dont2@2x.png";
import specimen from "../../../../src/images/typography/RocketTypeSpecimenGraphic-01.png";
import specimen2x from "../../../../src/images/typography/RocketTypeSpecimenGraphic-01@2x.png";
import TypographyTable from "../../../../src/components/TypographyTable";
import * as React from 'react';
export default {
  ComponentPreview,
  SprkStack,
  SprkStackItem,
  SprkFlag,
  SprkHeading,
  SprkIcon,
  SprkDivider,
  SprkText,
  pageTitleDo,
  pageTitleDo2x,
  pageTitleDont,
  pageTitleDont2x,
  pageTitleDont2,
  pageTitleDont2_2x,
  specimen,
  specimen2x,
  TypographyTable,
  React
};