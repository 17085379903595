import accessibilityKey from "../../../../src/images/accessibility-key.png";
import accessibilityKey2x from "../../../../src/images/accessibility-key@2x.png";
import accessibilityKey3x from "../../../../src/images/accessibility-key@3x.png";
import chart1HighRes from "../../../../src/images/contrast-chart-1-high-res.jpg";
import chart2HighRes from "../../../../src/images/contrast-chart-2-high-res.jpg";
import grayBox from "../../../../src/images/gray-box-contrast-key.svg";
import greenBox from "../../../../src/images/green-box-contrast-key.svg";
import orangeBox from "../../../../src/images/orange-box-contrast-key.svg";
import accessibilityChart1 from "../../../../src/images/accessibility-chart-1.png";
import accessibilityChart12x from "../../../../src/images/accessibility-chart-1@2x.png";
import accessibilityChart13x from "../../../../src/images/accessibility-chart-1@3x.png";
import accessibilityChart2 from "../../../../src/images/accessibility-chart-2.png";
import accessibilityChart22x from "../../../../src/images/accessibility-chart-2@2x.png";
import accessibilityChart23x from "../../../../src/images/accessibility-chart-2@3x.png";
import { SprkLink, SprkIcon, SprkDivider, SprkStack, SprkStackItem } from '@sparkdesignsystem/spark-react';
import * as React from 'react';
export default {
  accessibilityKey,
  accessibilityKey2x,
  accessibilityKey3x,
  chart1HighRes,
  chart2HighRes,
  grayBox,
  greenBox,
  orangeBox,
  accessibilityChart1,
  accessibilityChart12x,
  accessibilityChart13x,
  accessibilityChart2,
  accessibilityChart22x,
  accessibilityChart23x,
  SprkLink,
  SprkIcon,
  SprkDivider,
  SprkStack,
  SprkStackItem,
  React
};