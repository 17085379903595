import ComponentPreview from "../../../../src/components/ComponentPreview";
import { SprkDivider } from '@sparkdesignsystem/spark-react';
import typingExample from "../../../../src/images/autocomplete/Active Typing@2x.png";
import selectingExample from "../../../../src/images/autocomplete/Selecting Options@2x.png";
import noResultsFoundExample from "../../../../src/images/autocomplete/No Results Found@2x.png";
import noSearchIconExample from "../../../../src/images/autocomplete/No Search Icon@2x.png";
import twoLinesExample from "../../../../src/images/autocomplete/address_two_lines@2x.png";
import noLabelExample from "../../../../src/images/autocomplete/no_label@2x.png";
import categoriesExample from "../../../../src/images/autocomplete/search_across_categories@2x.png";
import recentSearchesExample from "../../../../src/images/autocomplete/focused_without_search@2x.png";
import * as React from 'react';
export default {
  ComponentPreview,
  SprkDivider,
  typingExample,
  selectingExample,
  noResultsFoundExample,
  noSearchIconExample,
  twoLinesExample,
  noLabelExample,
  categoriesExample,
  recentSearchesExample,
  React
};